/*body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
  font-family: "Open Sans", sans-serif;
*/
@import url("https://fonts.googleapis.com/css2?family=Pirata+One&display=swap");

body {
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.primaryButton {
  background-color: #007bff; /* Green */
  border: none;
  color: white;
  padding: 1em 1.5em;
  text-align: center;
  text-decoration: none;
  font-size: 1.1em;
  border-radius: 3px;
  cursor: pointer;
  font-weight: bold;
}

.primaryButton:hover {
  background-color: #007bffc2;
}

.home {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 10em;
}

.home > img {
  min-width: 30em;
}

.home > span {
  font-size: 2em;
}

.home > * {
  margin: 10px;
}

.mainContent {
  width: 100%;
}

nav {
  height: 4em;
  width: 90%;
  margin: 5px auto;
  display: flex;
}
nav img {
  height: 4em;
}

nav > ul {
  list-style-type: none;
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
}
nav > ul > li a {
  color: #343a40;
  text-align: center;
  padding: 0.2em;
  text-decoration: none;
  font-weight: bold;
  font-family: "Pirata One";
  font-size: 2em;
}

a:hover {
  color: #dd3849;
}

.videoFrame {
  width: 100%;
  height: 27em;
}
.videoCall {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.videoCall > h2 {
  font-family: "Pirata One";
  color: #007bff;
}

.game {
  display: none;
  padding: 0 0 0 1em;
  /*box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);*/
}

.sectionHeader h1 {
  font-family: "Pirata One";
  color: #007bff;
  margin-bottom: 0;
  padding: 0;

  /*border-bottom-style: solid;*/
}
.sectionHeader h4 {
  margin-top: 0;
}
.sectionHeader {
  margin-bottom: 1rem;
  display: flex;
}

.choiceItem {
  background-color: #d5e5ff;
  /*background-color: #e5e5e5;*/
  height: 5em;
  margin-bottom: 0.7em;
  display: flex;
  align-items: center;
  padding-left: 1em;
  padding-right: 1em;
}

.choiceItem h2 {
  font-family: "Pirata One";
  color: #343a40;
  margin: 0;
}

.choiceItem img {
  height: 3em;
  margin-right: 0.5em;
}

.rightFlex {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
}

.sectionHeader .rightFlex div {
  background-color: black;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: 1em;
  padding-right: 1em;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  min-width: 6.5em;
}

.sectionHeader .redButton {
  margin-left: 0.5em;
}

.redButton {
  background-color: #dd3849; /* Green */
  border: none;
  color: white;
  width: 3em;
  height: 3em;
  text-align: center;
  text-decoration: none;
  font-size: 1.1em;
  border-radius: 3px;
  cursor: pointer;
  font-weight: bold;
}

.redButton:hover {
  background-color: #f25363;
}
