@import url(https://fonts.googleapis.com/css2?family=Pirata+One&display=swap);
/*body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
  font-family: "Open Sans", sans-serif;
*/

body {
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.primaryButton {
  background-color: #007bff; /* Green */
  border: none;
  color: white;
  padding: 1em 1.5em;
  text-align: center;
  text-decoration: none;
  font-size: 1.1em;
  border-radius: 3px;
  cursor: pointer;
  font-weight: bold;
}

.primaryButton:hover {
  background-color: #007bffc2;
}

.home {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-top: 10em;
}

.home > img {
  min-width: 30em;
}

.home > span {
  font-size: 2em;
}

.home > * {
  margin: 10px;
}

.mainContent {
  width: 100%;
}

nav {
  height: 4em;
  width: 90%;
  margin: 5px auto;
  display: -webkit-flex;
  display: flex;
}
nav img {
  height: 4em;
}

nav > ul {
  list-style-type: none;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-flex-grow: 1;
          flex-grow: 1;
}
nav > ul > li a {
  color: #343a40;
  text-align: center;
  padding: 0.2em;
  text-decoration: none;
  font-weight: bold;
  font-family: "Pirata One";
  font-size: 2em;
}

a:hover {
  color: #dd3849;
}

.videoFrame {
  width: 100%;
  height: 27em;
}
.videoCall {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.videoCall > h2 {
  font-family: "Pirata One";
  color: #007bff;
}

.game {
  display: none;
  padding: 0 0 0 1em;
  /*box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);*/
}

.sectionHeader h1 {
  font-family: "Pirata One";
  color: #007bff;
  margin-bottom: 0;
  padding: 0;

  /*border-bottom-style: solid;*/
}
.sectionHeader h4 {
  margin-top: 0;
}
.sectionHeader {
  margin-bottom: 1rem;
  display: -webkit-flex;
  display: flex;
}

.choiceItem {
  background-color: #d5e5ff;
  /*background-color: #e5e5e5;*/
  height: 5em;
  margin-bottom: 0.7em;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding-left: 1em;
  padding-right: 1em;
}

.choiceItem h2 {
  font-family: "Pirata One";
  color: #343a40;
  margin: 0;
}

.choiceItem img {
  height: 3em;
  margin-right: 0.5em;
}

.rightFlex {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.sectionHeader .rightFlex div {
  background-color: black;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
  padding-left: 1em;
  padding-right: 1em;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  min-width: 6.5em;
}

.sectionHeader .redButton {
  margin-left: 0.5em;
}

.redButton {
  background-color: #dd3849; /* Green */
  border: none;
  color: white;
  width: 3em;
  height: 3em;
  text-align: center;
  text-decoration: none;
  font-size: 1.1em;
  border-radius: 3px;
  cursor: pointer;
  font-weight: bold;
}

.redButton:hover {
  background-color: #f25363;
}

.nocazzen-style_display__1t4zM {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  border-style: solid;
  border-color: gainsboro;
}
.nocazzen-style_playerInfo__krN7s {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column wrap;
          flex-flow: column wrap;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

.nocazzen-style_emphasisRed__27vX4 {
  font-family: "Pirata One";
  font-size: x-large;
  color: #dd3849;
}

.nocazzen-style_emphasisBlue__2URiN {
  font-family: "Pirata One";
  font-size: x-large;
  color: #007bff;
}

.nocazzen-style_itemAndSubtitle__L690s {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.nocazzen-style_card__KRaZ2 {
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  width: 4rem;
  height: 6rem;
  display: -webkit-flex;
  display: flex;
  border-radius: 0.5rem;
}
.nocazzen-style_cardText__2dsaB {
  margin: auto;
  font-size: x-large;
}

.nocazzen-style_cardAndCounters__3AXOL {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-grow: row wrap;
          flex-grow: row wrap;

  background-color: whitesmoke;
  margin-bottom: 2rem;
  -webkit-align-items: center;
          align-items: center;
}

.nocazzen-style_counters__7fVkQ {
  width: 4rem;
  height: 6rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column wrap;
          flex-flow: column wrap;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

/* .counter {
  height: 10%;
  background-color: cornsilk;
  border-radius: 0.2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
} */

.nocazzen-style_counter__4xq41 {
  border: 0.2rem solid orange;
  background-color: papayawhip;
  border-radius: 1rem;
  height: 2rem;
  width: 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.nocazzen-style_counterBox__2OWT5 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  width: 16rem;
  height: 14rem;
  -webkit-align-content: flex-start;
          align-content: flex-start;
  margin-left: 1rem;
}

.nocazzen-style_countersNumber__2VYVP {
  position: absolute;
  font-size: x-large;
  width: 4rem;
  text-align: center;
  text-shadow: 0 2px 4px white;
}

.nocazzen-style_userCardsShow__18mHw,
.nocazzen-style_userCounters__3GslG {
  display: -webkit-flex;
  display: flex;
  width: 25rem;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
}

.nocazzen-style_userCard__1ihRn {
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  border-radius: 0.5rem;
  width: 2rem;
  height: 3rem;
  text-align: center;
  margin: 0.1rem;
  line-height: 3rem; /* to center 1 line of text in a div*/
}

.nocazzen-style_plainCounter__PcA-L {
  background-color: cornsilk;
  margin: 0.1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  border-radius: 1rem;

  width: 2rem;
  height: 2rem;
}

.nocazzen-style_otherPlayersInfo__3Tave {
  word-break: break-all;
  -webkit-flex-basis: 48%;
          flex-basis: 48%;
  background-color: #d5e5ff;
  margin: 1%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.nocazzen-style_otherPlayerName__3UPbt {
  margin-bottom: 0;
  padding: 0;
}

.nocazzen-style_scores__qtlUe {
  display: -webkit-flex;
  display: flex;
}

.nocazzen-style_display__1t4zM h5 {
  margin: 1rem 0 0.2rem 0;
}

