.display {
  display: flex;
  flex-flow: row wrap;
  border-style: solid;
  border-color: gainsboro;
}
.playerInfo {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
}

.emphasisRed {
  font-family: "Pirata One";
  font-size: x-large;
  color: #dd3849;
}

.emphasisBlue {
  font-family: "Pirata One";
  font-size: x-large;
  color: #007bff;
}

.itemAndSubtitle {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card {
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  width: 4rem;
  height: 6rem;
  display: flex;
  border-radius: 0.5rem;
}
.cardText {
  margin: auto;
  font-size: x-large;
}

.cardAndCounters {
  display: flex;
  flex-grow: row wrap;

  background-color: whitesmoke;
  margin-bottom: 2rem;
  align-items: center;
}

.counters {
  width: 4rem;
  height: 6rem;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-evenly;
}

/* .counter {
  height: 10%;
  background-color: cornsilk;
  border-radius: 0.2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
} */

.counter {
  border: 0.2rem solid orange;
  background-color: papayawhip;
  border-radius: 1rem;
  height: 2rem;
  width: 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.counterBox {
  display: flex;
  flex-wrap: wrap;
  width: 16rem;
  height: 14rem;
  align-content: flex-start;
  margin-left: 1rem;
}

.countersNumber {
  position: absolute;
  font-size: x-large;
  width: 4rem;
  text-align: center;
  text-shadow: 0 2px 4px white;
}

.userCardsShow,
.userCounters {
  display: flex;
  width: 25rem;
  flex-flow: row wrap;
}

.userCard {
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  border-radius: 0.5rem;
  width: 2rem;
  height: 3rem;
  text-align: center;
  margin: 0.1rem;
  line-height: 3rem; /* to center 1 line of text in a div*/
}

.plainCounter {
  background-color: cornsilk;
  margin: 0.1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  border-radius: 1rem;

  width: 2rem;
  height: 2rem;
}

.otherPlayersInfo {
  word-break: break-all;
  flex-basis: 48%;
  background-color: #d5e5ff;
  margin: 1%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.otherPlayerName {
  margin-bottom: 0;
  padding: 0;
}

.scores {
  display: flex;
}

.display h5 {
  margin: 1rem 0 0.2rem 0;
}
